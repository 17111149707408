const initUserInfo = () => ({
  email: '',
  realName: '',
});

const state = {
  userInfo: initUserInfo(),
};

const mutations = {
  SAVE_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  REMOVE_USERINFO() {
    state.userInfo = initUserInfo();
  },
};

const actions = {};

const getters = {
  userInfo(state) {
    return state.userInfo;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
