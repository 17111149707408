import Layout from '@/layout';

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/live/list',
    name: 'livelist',
    alwaysShow: true,
    index: 1,
    meta: { title: '直播', icon: 'el-icon-live-broadcast', permissions: ['live:list'] },
    children: [
      {
        path: 'live/list',
        name: 'liveList',
        component: () => import('@/modules/live/list'),
        meta: { title: '直播台', permissions: ['live:console:list'] },
      },
      {
        path: 'live/list/radio',
        name: 'radioRoom',
        hidden: true,
        component: () => import('@/modules/live/live'),
        meta: { title: '直播详情' },
      },
      {
        path: 'live/setting',
        name: 'liveSetting',
        hidden: true,
        component: () => import('@/modules/live/setting'),
        meta: { title: '直播配置' },
      },
    ],
  },
];

export default routes;
