import Layout from '@/layout';

const routes = [
  {
    path: '/authorization',
    index: 45,
    component: Layout,
    name: 'authorization',
    alwaysShow: true,
    meta: { title: '公众号', icon: 'el-icon-setting', permissions: ['wxmp:list'] },
    children: [
      {
        path: '',
        name: 'authorizationIndex',
        component: () => import('@/modules/authorization/index'),
        meta: { title: '公众号管理', permissions: ['wxmp:wxapp:list'] },
      },
      {
        path: 'detail',
        hidden: true,
        name: 'authorizationDetail',
        component: () => import('@/modules/authorization/detail'),
        meta: { title: '公众号助手', permissions: ['authorization:page:assistant'] },
      },
    ],
  },
];

export default routes;
