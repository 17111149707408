import api from '@/core/request';
import Vue from 'vue';
import router from './router';
import store from '../../store';

const state = {
  batchTagDialogVisible: false,
  remarkDialogVisible: false,
  personred: {
    messageCoult: 0,
    userList: [],
  },
  isTraffc: false,
};

const dotItem = (dot) => {
  const personMsg = router.find(item => item.path === '/audience');
  let roaditem = personMsg?.children?.find(item => item.name === 'personMsg');
  if (roaditem.children.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    roaditem = roaditem.children[0];
  }
  Vue.prototype.$set(roaditem.meta, 'dot', dot);
};

const mutations = {
  CHANGE_BATCH(state, batchTagDialogVisible) {
    state.batchTagDialogVisible = batchTagDialogVisible;
  },
  CHANGE_REMARK(state, remarkDialogVisible) {
    state.remarkDialogVisible = remarkDialogVisible;
  },
  SET_PERSONRED: (state, data) => {
    state.personred = data;
    if (state.isTraffc) {
      return;
    }
    dotItem(data.messageCoult > 0);
  },
  SET_PERSON_NOT(state, isTraffc) {
    dotItem(false);
    state.isTraffc = isTraffc;
  },
};

const actions = {
  // 获取私信推送的小红点
  getPersonRedTitle({ commit }) {
    api.post('/business/userManager/getRedCallRecord', { radioId: store.state.radio?.radioId }).then((res) => {
      commit('SET_PERSONRED', res);
    });
  },
};

const getters = {
  batchTagDialogVisible: state => state.batchTagDialogVisible,
  remarkDialogVisible: state => state.remarkDialogVisible,
  personred: state => state.personred,
};

const obj = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
export default obj;
