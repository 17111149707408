<template>
  <div class="sidebar-logo-container">
    <router-link
      class="sidebar-logo-link"
      to="/"
    >
      <svg-icon
        icon-class="sidebar-logo"
        class="sidebar-logo"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  data() {
    return {
      title: 'FM+',
      logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  overflow: hidden;
  margin-top: 64px;
  margin-bottom: 45px;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    .sidebar-logo {
      width: 64px;
      height: 64px;
      vertical-align: middle;
      margin: 8px auto 8px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #000;
      font-weight: 600;
      line-height: 80px;
      font-size: 50px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }
}
</style>
