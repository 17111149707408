import Vuex from 'vuex';
import Vue from 'vue';
import api from '@/core/request';
import $log from '@/core/log';
import live from '@/modules/live/store';
import router from './router';

Vue.use(Vuex);

const STORAGE_TOKEN_KEY = 'fmb_token';
const STORAGE_WXINFO = 'wxInfo';

const getDefaultState = () => ({
  token: localStorage.getItem(STORAGE_TOKEN_KEY),
  userInfo: {
    userId: '',
    email: '',
    nickName: '',
    avatar: '',
  },
  isTraffc: false,
  routes: router.options.routes,
  featureInfo: {},
  permissions: [],
  roles: [],
  pagestatus: 'login',
  roadredtitle: '1',
  editRecordList: [],
});

const setStoredToken = (token) => {
  localStorage.setItem(STORAGE_TOKEN_KEY, token);
};

const setStoredWxInfo = (wxInfo) => {
  localStorage.setItem(STORAGE_WXINFO, JSON.stringify(wxInfo));
};

const setAPiToken = (token) => {
  api.defaults.headers['FMB-Auth'] = token;
};

const dotItem = (dot) => {
  const trafficstatus = state.routes.find(item => item.path === '/trafficstatus');
  const roaditem = trafficstatus?.children.find(item => item.name === 'trafficstatus');
  Vue.prototype.$set(roaditem.meta, 'dot', dot);
};

const state = getDefaultState(); // 默认state，直接读localStorage
if (typeof state.token === 'string' && state.token !== '') {
  api.defaults.headers['FMB-Auth'] = state.token;
}

const getters = {
  sidebar: state => state.sidebar,
  device: state => state.device,
  token: state => state.token,
  userInfo: state => state.userInfo,
  live: state => state.live,
  roles: state => state.roles,
  permissions: state => state.permissions,
  radio: state => state.radio,
  featureInfo: state => state.featureInfo,
  pagestatus: state => state.pagestatus,
  routes: state => state.routes,
  roadredtitle: state => state.roadredtitle,
  editRecordList: state => state.editRecordList,
};

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_PERMISSION: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_RADIO_INFO: (state, radio) => {
    state.radio = radio;
  },
  REMOVE_STORAGE_TOKEN: () => {
    localStorage.removeItem(STORAGE_TOKEN_KEY);
  },
  SET_FEATURE_INFO: (state, feature) => {
    state.featureInfo = feature;
  },
  SET_PAGE_STATUS: (state, pagestatus) => {
    state.pagestatus = pagestatus;
  },
  // 实时路况新消息推送小红点
  SET_ROADRED: (state, roadredtitle) => {
    state.roadredtitle = roadredtitle;
    if (state.isTraffc) {
      return;
    }
    dotItem(roadredtitle === '0');
  },
  SET_ROAD_DOT(state, isTraffc) {
    dotItem(false);
    state.isTraffc = isTraffc;
  },
  SET_TAB_SHOW(state, tabShow) {
    state.roadredtitle = tabShow;
  },
  SET_EDIT_RECORD(state, list) {
    state.editRecordList = list || [];
  },
};

const actions = {
  // user login
  login({ commit }, loginInfo) {
    return new Promise((resolve, reject) => {
      api
        .post('login', loginInfo, { headers: null })
        .then(({ token }) => {
          setAPiToken(token);
          commit('SET_TOKEN', token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  scancode({ commit }, { code, state }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/weixin/scancode/callback?code=${code}&state=${state}`, { headers: null })
        .then(({ bindState, token, wechatUser = {} }) => {
          if (bindState === 1) {
            // binded
            setAPiToken(token);
            commit('SET_TOKEN', token);
            resolve(wechatUser);
          } else {
            setStoredWxInfo(wechatUser);
            commit('SET_PAGE_STATUS', 'bind');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user bind
  bindAccount({ commit }, accountInfo) {
    return new Promise((resolve, reject) => {
      api
        .post('/weixin/scancode/wxBindUser', accountInfo, { headers: null })
        .then(({ token }) => {
          setAPiToken(token);
          commit('SET_TOKEN', token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .get(`getInfo?token=${state.token}`)
        .then(({ user, roles, permissions, radio }) => {
          // 修改过密码
          if (user.pwdNumber > 0) {
            setStoredToken(state.token);
            commit('SET_USER_INFO', user);
            commit('SET_RADIO_INFO', radio);
            commit('SET_ROLES', roles);
            commit('SET_PERMISSION', permissions);
            $log.setConfig({ uin: user.email, ext1: radio.radioId });
            const { nickName, avatar, pwdNumber } = user;
            resolve({ nickName, avatar, pwdNumber });
            return;
          }
          commit('auth/SAVE_USERINFO', user);
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .post('logout', { token: state.token })
        .then(() => {
          localStorage.removeItem(STORAGE_TOKEN_KEY);
          commit('RESET_STATE');
          window.location.reload();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('REMOVE_STORAGE_TOKEN');
      commit('RESET_STATE');
      resolve();
    });
  },
  getFeatureConfig({ commit }) {
    api.getConfig('/sinan/fmb/feature/config').then((res) => {
      commit('SET_FEATURE_INFO', res);
    });
  },
  // 获取实时路况新消息推送的小红点
  getRoadRedTitle({ commit }) {
    api.post('business/road/getRoadRedTitle').then((res) => {
      commit('SET_ROADRED', res.state);
    });
  },
  // 获取编辑记录
  getEditRecordList({ commit }, { moduleId, targetId }) {
    api.post('/business/modifyHistory/list', { moduleId, targetId }).then((res) => {
      commit('SET_EDIT_RECORD', res);
    });
  },
};

const modules = {
  live,
};
/**
 * 加载子模块store
 */
const stores = require.context('./modules/', true, /store.js$/);
stores.keys().forEach((key) => {
  const [, moduleName] = key.split('/');
  modules[moduleName] = stores(key).default;
});

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules,
});

export default store;
