<template>
  <div
    v-if="!item.hidden"
    v-permission="item.meta && item.meta.permissions"
    class="sidebar-item__wrapper"
  >
    <template
      v-if="
        item.onlyOneChild && !item.alwaysShow
      "
    >
      <app-link
        v-if="item.onlyOneChild.meta"
        :to="resolvePath(item.onlyOneChild.path)"
      >
        <item
          :icon="item.onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
          :title="item.onlyOneChild.meta.title"
          :dotshow="item.onlyOneChild.meta.dot"
        />
      </app-link>
    </template>

    <div
      v-else
      v-permission="item.meta && item.meta.permissions"
      :index="resolvePath(item.path)"
    >
      <app-link
        v-if="item.meta"
        :to="resolvePath(item.path)"
      >
        <item
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
          :dotshow="item.meta.dot"
        />
      </app-link>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(item.path)"
        class="nest-menu"
      />
    </div>
  </div>
</template>
<script>
import path from 'path';
import { isExternal } from '@/core/utils/validate';
import Item from './Item';
import AppLink from './Link';
export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  methods: {
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>
