// 监控
import { Error } from './constant';

const composeLog = (msg, ...options) => [msg, ...options]
  .map((item) => {
    if (Object.prototype.toString.apply(item) === '[object Object]') {
      return JSON.stringify(item, null, 2);
    }
    if (Object.prototype.toString.apply(item) === '[object Array]') {
      return JSON.stringify(item);
    }
    return item;
  })
  .join('\n');

// 登录成功后需要通过 aegis.setConfig({uin: [userID]}) 设置用户ID，以便日志追踪
/**
 * TmsLogger TMS日志管理器
 */
class TmsLogger {
  aegis = null;
  localLog = false;
  constructor(projectID, localLog = false) {
    if (projectID) {
      this.aegis = new Aegis({
        id: process.env.VUE_APP_AEGIS_ID, // 项目ID，即上报key
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        api: {
          retCodeHandler(data) {
            let parsedData = {};
            try {
              parsedData = JSON.parse(data);
            } catch (e) {
              // 返回值非标准json
              return {
                isErr: true,
                code: Error.Unknown,
              };
            }
            return {
              isErr: parsedData.errCode !== 0 || (parsedData.resData.code > 0 && parsedData.resData.code < 1000),
              code: parsedData.errCode || parsedData.resData.code,
            };
          },
        },
      });
    }
    this.localLog = localLog;
  }
  setConfig(cfg) {
    this.aegis.setConfig(cfg);
  }
  /**
   * 日志打印
   * @param {String|Object} msg 消息内容
   * @param  {...any} options Console 选项
   */
  info(msg, ...options) {
    if (this.aegis) {
      this.aegis.info(composeLog(msg, ...options));
    }
    if (this.localLog) {
      console.log(msg, ...options);
    }
  }
  isWhiteList() {
    return this.aegis.isWhiteList;
  }
  /**
   * 日志打印，强制发到远程
   * @param {String|Object} msg 消息内容
   * @param  {...any} options Console 选项
   */
  logRemote(msg, ...options) {
    const content = composeLog(msg, ...options);
    if (this.aegis) {
      this.aegis.infoAll(content);
    }
    if (this.localLog) {
      console.log('%c[Remote]%c', 'color: #159030;', 'color: #222;', content);
    }
  }
  /**
   * 错误日志
   * @param {Error} err 消息内容
   * @param  {...any} options Console 选项
   */
  error(err) {
    if (this.aegis) {
      this.aegis.error(err);
    }
    if (this.localLog) {
      console.error(err);
    }
  }
  /**
   * 事件上报
   * @param {String|Object} evt 事件
   * @param {String} evt.name 事件名称
   * @param {String} evt.ext1 扩展参数一
   * @param {String} evt.ext2 扩展参数二
   * @param {String} evt.ext3 扩展参数三
   */
  reportEvent(evt) {
    if (this.aegis) {
      this.aegis.reportEvent(evt);
    }
  }
}
// 全局唯一实例
const $log = new TmsLogger(process.env.VUE_APP_AEGIS_ID, process.env.VUE_APP_LOG_LOCALENABLED);

export default $log;
