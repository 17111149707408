import Layout from '@/layout';

const routes = [
  {
    path: '/role',
    component: Layout,
    alwaysShow: true,
    index: 45,
    redirect: '/role/roleindex',
    name: 'role',
    meta: { title: '系统管理', icon: 'el-icon-s-fold', permissions: ['system:list'] },
    children: [
      {
        path: 'roleindex',
        name: 'roleindex',
        component: () => import('@/modules/rolemanagement'),
        meta: { title: '角色管理', permissions: ['system:role:list'] },
      },
      {
        path: 'edit',
        hidden: true,
        meta: { title: '角色编辑' },
      },
    ],
  },
];

export default routes;
