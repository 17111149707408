import Layout from '@/layout';

const routes = [
  {
    path: '/setting',
    index: 40,
    component: Layout,
    redirect: '/setting/basicinformation',
    name: 'settingList',
    meta: { title: '设置', icon: 'el-icon-setting', permissions: ['setting:list'] },
    children: [
      {
        path: 'basicinformation',
        name: 'settingList',
        component: () => import('@/modules/setting/basicinformation'),
        meta: { title: '基本信息', permissions: ['setting:baseinfo:list'] },
      },
      {
        path: 'management',
        name: 'settingListManagement',
        component: () => import('@/modules/setting/management'),
        meta: { title: '人员管理', permissions: ['setting:people:list'] },
      },
    ],
  },
];

export default routes;
