import Layout from '@/layout';

const routes = [
  {
    path: '/resource',
    component: Layout,
    redirect: '/resource/indexcontent',
    name: 'resourceList',
    meta: { title: '资源管理', icon: 'el-icon-source', permissions: ['resmager:list'] },
    index: 15,
    children: [
      {
        path: 'indexcontent',
        name: 'indexcontent',
        component: () => import('@/modules/resource/indexcontent'),
        meta: { title: '首页内容', permissions: ['resmager:operposition:list'] },
      },
      {
        path: 'application',
        name: 'application',
        hidden: true,
        component: () => import('@/modules/resource/application'),
        meta: { title: '首页应用', permissions: ['resmager:app:list'] },
      },
      {
        path: 'prize',
        name: 'prize',
        component: () => import('@/modules/resource/prize'),
        meta: { title: '奖品', permissions: ['resmager:award:list'] },
        children: [
          {
            path: '',
            name: '',
            hidden: true,
            component: () => import('@/modules/resource/components/prizecontent/index'),
            meta: { title: '奖品', permissions: ['resmager:award:list'] },
          },
          {
            path: 'create',
            name: 'create',
            hidden: true,
            component: () => import('@/modules/resource/components/prizecontent/form'),
            meta: { title: '创建奖品' },
          },
          {
            path: 'edit',
            name: 'edit',
            hidden: true,
            component: () => import('@/modules/resource/components/prizecontent/form'),
            meta: { title: '编辑奖品' },
          },
          {
            path: 'seen',
            name: 'seen',
            hidden: true,
            component: () => import('@/modules/resource/components/prizecontent/form'),
            meta: { title: '查看奖品' },
          },
        ],
      },
    ],
  },
];

export default routes;
