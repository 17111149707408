import Clipboard from 'clipboard';
import { Message } from 'element-ui';

const VueClipboardConfig = {
  autoSetContainer: false,
  appendToBody: true, // This fixes IE
};

const VueClipboard = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$copyText = function (text, container) {
      return new Promise((resolve, reject) => {
        const fakeElement = document.createElement('button');
        const clipboard = new Clipboard(fakeElement, {
          text() {
            return text;
          },
          action() {
            return 'copy';
          },
          container: typeof container === 'object' ? container : document.body,
        });
        clipboard.on('success', (e) => {
          clipboard.destroy();
          Message.success('复制成功');
          resolve(e);
        });
        clipboard.on('error', (e) => {
          clipboard.destroy();
          reject(e);
        });
        if (VueClipboardConfig.appendToBody) document.body.appendChild(fakeElement);
        fakeElement.click();
        if (VueClipboardConfig.appendToBody) document.body.removeChild(fakeElement);
      });
    };
  },
  config: VueClipboardConfig,
};

export default VueClipboard;
