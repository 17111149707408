<template>
  <div class="navbar">
    <div class="right-menu">
      <avatar />
    </div>
  </div>
</template>

<script>
import Avatar from './Avatar';

export default {
  components: {
    Avatar,
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  // height: 80px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  border-radius: 29px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .14);

  .right-menu {
    float: right;
    height: 58px;
    width: 119px;
    display: flex;
    align-items: center;
    border-radius: 29px;
    background-color: #fff;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    .navbar-message {
      margin-right: 30px;
    }
  }
}
</style>
