import store from '@/store';

function checkPermission(el, binding) {
  const { value } = binding;
  const permissions = store.getters?.permissions;

  if (value && value instanceof Array) {
    if (value.length > 0) {
      if (permissions[0] === '*:*:*') {
        const hasPermission = true;
        return hasPermission;
      }
      // eslint-disable-next-line
      const hasPermission = value.some((feature) => [...permissions].includes(feature));
      if (!hasPermission) {
        el.parentNode?.removeChild(el);
      }
    }
  } else {
    if (typeof value === 'undefined') {
      return;
    }
    throw new Error('需要传如权限。例如：v-permission="[\'system:user:resetPwd\']"');
  }
}

const permission = {
  inserted(el, binding) {
    checkPermission(el, binding);
  },
  update(el, binding) {
    checkPermission(el, binding);
  },
};

export default permission;
