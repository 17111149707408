import axios from 'axios';
import $log from '@/core/log';
import { Error as ErrorConstant } from './constant';
import { Message, MessageBox } from 'element-ui';
import store from '../store';


const TIME_OUT = 10000;

// create an axios instance
const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: TIME_OUT, // request timeout
});

function isExport(path) {
  return path.indexOf('fmbexport') > -1;
}

const commonRequestInterceptor = {
  onFullFill: (cfg) => {
    const config = cfg;
    const { method, data = {}, params = {}, url } = config;
    if (isExport(url)) {
      config.baseURL = '';
      config.responseType = 'arraybuffer';
    }
    const ts = new Date().getTime();
    const random = Math.random().toString()
      .slice(-6);
    const seqId = `${ts}${random}`;
    if (['post', 'put', 'patch'].indexOf(method) < 0) {
      params.seqId = seqId;
      params.timestamp = ts;
    } else {
      data.seqId = seqId;
      data.timestamp = ts;
    }
    Object.assign(config, { data, params });
    if (!$log.isWhiteList()) {
      // 白名单用户默认都会打所有接口请求，不需要
      $log.logRemote(`${seqId}|[${method}]|${url.split('?')[0]}`);
    }
    return config;
  },
  onReject: (error) => {
    const err = { code: ErrorConstant.Network, msg: '请求失败，请检查本地网络', error };
    return Promise.reject(err);
  },
};

// 请求拦截器
api.interceptors.request.use(commonRequestInterceptor.onFullFill, commonRequestInterceptor.onReject);

// 返回拦截器
api.interceptors.response.use(
  (response) => {
    const { responseURL: url } = response.request;
    // 接入层错误处理
    const { errCode, errMsg, resData } = response.data;
    if (isExport(url) && errCode !== 0) {
      return response.data;
    }

    if (errCode !== 0) {
      // 接入层系统错误
      const fullErrInfo = {
        code: ErrorConstant.Gateway,
        msg: '未知系统错误，请稍后重试',
        error: new Error(`接口${url}|调用失败|接入层错误|${errCode}|${errMsg}|${resData}`),
      };
      return Promise.reject(fullErrInfo);
    }
    const { code, data, msg } = resData;
    if (code === 0) {
      return data;
    }
    if (code < 1000) {
      // 下游非业务异常
      if ([401, 403].indexOf(code) >= 0) {
        // 非法token，刷新页面，重新登录
        // to re-login
        if (window.location.href.indexOf('/auth/login') < 0) {
          MessageBox.confirm('您的登录状态已过期，请您重新登录。', '重新登录', {
            showCancelButton: false,
            closeOnClickModal: false,
            showClose: false,
            confirmButtonText: '重新登录',
          }).then(() => {
            store.commit('REMOVE_STORAGE_TOKEN');
            window.location.reload();
          });
        }
        return Promise.reject({
          code,
          msg: '登录状态失效',
          error: new Error(`接口${url}|失败|${msg}|${JSON.stringify(response)}`),
        });
      }
      return Promise.reject({
        code,
        msg: '未知系统错误，请稍后重试',
        error: new Error(`接口${url}|失败|${msg}|${JSON.stringify(response)}`),
      });
    }
    // 业务错误，返回下游内容
    return Promise.reject(resData);
  },
  (error) => {
    const packedError = {
      code: ErrorConstant.Unknown,
      msg: '未知系统错误，请稍后重试',
      error,
    };
    if (packedError.error.message === 'Network Error') {
      packedError.code = ErrorConstant.Network;
      packedError.msg = '网络异常，请检查本机网络';
    }
    if (packedError.error.message.indexOf('timeout of ') > -1) {
      packedError.code = ErrorConstant.Timeout;
      packedError.msg = '接口返回超时，请稍后重试';
    }
    Message.error(packedError.msg);
    return Promise.reject(packedError);
  },
);

const commonApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: TIME_OUT,
});

api.uploadAPI = axios.create({
  timeout: TIME_OUT,
});

// 导出
api.export = function (url, data, name) {
  new Promise((resolve, reject) => {
    api.post(`/fmbexport${url}`, data).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.setAttribute('download', `${name}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve('success');
    }, reject);
  });
};

// 请求拦截器
commonApi.interceptors.request.use(commonRequestInterceptor.onFullFill, commonRequestInterceptor.onReject);

commonApi.interceptors.response.use(
  (response) => {
    const { responseURL: url } = response.request;
    // 接入层错误处理
    const { errCode, errMsg, resData } = response.data;
    if (errCode !== 0) {
      const fullErrInfo = {
        code: ErrorConstant.Gateway,
        msg: '未知系统错误，请稍后重试',
        error: new Error(`接口${url}|调用失败|接入层错误|${errCode}|${errMsg}|${resData}`),
      };
      return Promise.reject(fullErrInfo);
    }
    return resData;
  },
  (error) => {
    const packedError = {
      code: ErrorConstant.Unknown,
      msg: '未知系统错误，请稍后重试',
      error,
    };
    if (packedError.error.message === 'Network Error') {
      packedError.code = ErrorConstant.Network;
      packedError.msg = '网络异常，请检查本机网络';
    }
    if (packedError.error.message.indexOf('timeout of ') > -1) {
      packedError.code = ErrorConstant.Timeout;
      packedError.msg = '接口返回超时，请稍后重试';
    }
    return Promise.reject(packedError);
  },
);

api.getConfig = function (configPath, extendAttr = {}, defaultCfg = { open: true }) {
  if (Array.isArray(configPath)) {
    // 复数形式
    if (defaultCfg) {
      // 有默认值
      if (!Array.isArray(defaultCfg) || configPath.length !== defaultCfg.length) {
        throw new Error('配置路径和默认值的数组长度不一致');
      }
    }
  }

  const configPaths = Array.isArray(configPath) ? configPath : [configPath];
  const defaultCfgs = (defaultCfg && (Array.isArray(defaultCfg) ? defaultCfg : [defaultCfg])) || null;
  const extendAttrs = typeof extendAttr === 'string' ? extendAttr : JSON.stringify(extendAttr);

  return commonApi
    .post('/marketing/config', {
      extendAttrs,
      configPaths,
    })
    .then((resData = {}) => {
      if (resData && resData.length > 0) {
        const parsed = configPaths.map((path, index) => {
          const found = resData.find(cfg => cfg.configPath === path);

          if (found) {
            return JSON.parse(found.configValue);
          }

          if (defaultCfgs && defaultCfgs[index]) {
            return defaultCfgs[index];
          }

          return {}; // 没找到配置，返回一个空对象
        });

        if (Array.isArray(configPath)) {
          return parsed;
        }

        return parsed[0];
      }

      if (defaultCfgs) {
        return Array.isArray(configPath) ? defaultCfgs : defaultCfgs[0];
      }
    });
};

export default api;
