<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" />
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper"> -->
    <sidebar-item
      v-for="(route, index) in sourceRoutes"
      :key="index"
      :item="route"
      :base-path="route.path"
    />
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import Logo from './Logo';
import SidebarItem from './SidebarItem';

import { mapGetters } from 'vuex';

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  computed: {
    ...mapGetters(['routes']),
    sourceRoutes() {
      return this.computeRoutes(this.routes);
    },
    showLogo() {
      return true;
    },
  },
  methods: {
    computeRoutes(routes, deep = 1) {
      const arr = [];
      routes.forEach((item) => {
        const cur = item;
        if (item.hidden) {
          return;
        }

        if (item.children) {
          if (deep === 1 && this.hasOneShowingChild(item.children, item)) {
            this.onlyOneChild && (cur.onlyOneChild = this.onlyOneChild);
            this.onlyOneChild = null;
          }
          cur.children = this.computeRoutes(item.children, deep + 1);
        }
        arr.push(item);
      });
      return arr;
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        }
        this.onlyOneChild = item;
        return true;
      });
      if (showingChildren.length === 1) {
        return true;
      }
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
>>> .el-scrollbar__wrap {
  height: 88%;
}
</style>
