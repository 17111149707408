// 系统常量

/**
 * 错误编号
 */
const Error = {
  Unknown: -1, // 未知异常
  UnAuth: 5, // 未登录
  Network: 11, // 本地网络异常
  Gateway: 12, // 接入层错误
  Timeout: 13, // 网络超时
};

export { Error };
