import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import $api from './core/request';
import $log from './core/log';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n
import './core/utils/log';
// 样式
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/icons/iconfont/iconfont.css';
import '@/styles/index.scss'; // global css
import '@/assets/icons'; // icon

// 自定义主题
import '@/styles/theme/index.css';
// 指令
import './directive';
// 插件
import './plugins';
// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
import '@/core/fixtable';
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.prototype.$api = $api;
Vue.prototype.$log = $log;
Vue.prototype.$isProd = process.env.NODE_ENV === 'production';

// 挂载Vue错误处理，上报异常到远程日志
Vue.config.errorHandler = function (err, vm, info) {
  $log.error(`Error: ${err.toString()}\nInfo: ${info}\nElement:${vm.$el}`);
};

Vue.prototype.$getPermissions = (value) => {
  const permissions = store.getters.permissions || [];

  if (value && value instanceof Array) {
    if (store.getters.permissions[0] === '*:*:*') {
      const hasPermission = true;
      return hasPermission;
    }
    if (value.length > 0) {
      // eslint-disable-next-line
      const hasPermission = value.some((feature) => [...permissions].includes(feature));
      return hasPermission;
    }
  }
  return true;
};

new Vue({
  router,
  store,
  mounted() {
    this.$store.dispatch('getFeatureConfig');
  },
  // eslint-disable-next-line
  render: (h) => h(App),
}).$mount('#app');
