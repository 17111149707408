import Vue from 'vue';
const config =  {
  prefix: 'fm',
  blackList: [],
};
const { prefix, blackList } = config;

const blockHash = {};

blackList.forEach((item) => {
  // eslint-disable-next-line
  let { name, ...rest } = item;
  if (!name) {
    return;
  }
  name = delPrefix(name);
  blockHash[name.toLowerCase()] = rest;
});

// 混入生命周期
Vue.mixin({
  created() {
    const that = this;
    const { methods = {}, name: componentName = '' } = this.$options;

    const keys = Object.keys(methods);
    const eleReg = new RegExp(`${prefix}-?[a-zA-Z0-9]+`, 'ig');

    // 处理项目前缀，匹配组件name
    if (eleReg.test(componentName)) {
      const name = delPrefix(componentName).toLowerCase();

      // 代理匹配到的组件的methods
      const proxyMethods = new Proxy(methods, {
        get(target, methodName) {
          // 返回一个高阶函数
          return function (...args) {
            const handlers = (blockHash[name] && blockHash[name].handlers) || {};

            // 处理log配置的情况
            if (!handlers[methodName]) {
              _log(`${componentName}, ${methodName}被调用`, ...args);
            } else if (handlers[methodName] && !handlers[methodName].block) {
              if (
                typeof handlers[methodName].params === 'boolean'
                && !handlers[methodName].params
              ) {
                _log(`${componentName}, ${methodName}被调用`);
              } else {
                _log(`${componentName}, ${methodName}被调用`, ...args);
              }
            }

            return target[methodName].apply(that, args);
          };
        },
      });

      if (!blockHash[name] || (blockHash[name] && !blockHash[name].block)) {
        keys.forEach((method) => {
          that[method] = proxyMethods[method];
        });
      }
    }
  },
});

function delPrefix(name) {
  if (name.indexOf('-') > -1) {
    return name.replace(new RegExp(`${prefix}-`, 'ig'), '');
  }
  return name.replace(new RegExp(prefix, 'ig'), '');
}

function _log(...arg) {
  console.log(...arg);
}
