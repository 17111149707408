import Layout from '@/layout';

const routes = [
  {
    path: '/statistical',
    redirect: '/statistical/index',
    index: 30,
    component: Layout,
    meta: { title: '统计', icon: 'el-icon-statistics', permissions: ['fmcount:list'] },
    children: [
      {
        path: 'index',
        name: 'statistical',
        component: () => import('@/modules/statistical/index'),
        meta: { title: '统计', icon: 'el-icon-statistics', permissions: ['fmcount:list'] },
      },
    ],
  },
];

export default routes;
