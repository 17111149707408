import Layout from '@/layout';

const routes = [
  {
    path: '/programme',
    component: Layout,
    index: 5,
    redirect: '/programme/schedule',
    meta: { permissions: ['item:list'] },
    children: [
      {
        path: 'schedule',
        name: 'schedule',
        component: () => import('@/modules/programme/index'),
        meta: { title: '节目表', icon: 'el-icon-dashboard', permissions: ['item:list'] },
      },
    ],
  },
];

export default routes;
