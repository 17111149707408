import api from '@/core/request.js';

const state = {
  notice: {
    table: {
      list: [],
      pagination: {
        pageSize: 20,
        currPage: 1,
        totalCount: 0,
      },
    }, // 公告列表
    info: null,
  },
};

const mutations = {};

const actions = {
  async getNoticeTable({ state }, params) {
    const data = {
      currentPage: 1,
      pageSize: 20,
      ...params,
    };
    const { list, ...rest } = await api.post(
      '/business/road/selectNoticeMessage',
      data,
    );
    state.notice.table.list = list || [] ;
    state.notice.table.pagination.totalCount = rest.totalCount;
  },
  async getNoticeData({ state }) {
    const data = await api.post('/business/road/selectNoticeOnline');
    state.notice.info = data || null;
  },
  async changeNoticeInfo({ dispatch }, params) {
    await api.post('/business/road/addNoticeMessage', params);
    await dispatch('getNoticeData');
    await dispatch('getNoticeTable');
  },
};

const getters = {
  noticeTable(state) {
    return state.notice.table;
  },
  noticeInfo(state) {
    return state.notice.info;
  },
};

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default store;
