const state = {
  itemId: '',
  itemVoList: [],
  todoList: [],
  itemVo: [],
  topicId: 0,
  itemState: 0,
  fmDetail: {},
  userReplyContent: null,
  todayCount: {
    chatCountToday: 0,
    personCountToday: 0,
  },
  inputActive: '-1',
};

const mutations = {
  setItemId(state, id) {
    state.itemId = id;
  },
  setLiveCard(state, param) {
    state.itemVo = [param.itemVo];
    state.itemVoList = param.itemVoList;
    state.todoList = param.todoList;
  },
  setTopicId(state, param) {
    state.topicId = param;
  },
  setFminfo(state, param) {
    state.itemState = param.itemState;
    state.fmDetail = param.item;
  },
  setUserReplyContent(state, param) {
    state.userReplyContent = param;
    state.inputActive = 'text';
  },
  setTodayCount(state, param) {
    state.todayCount = param;
  },
  setInputActive(state, param) {
    state.inputActive = param;
  },
};

const actions = {
  liveCard(context, param) {
    context.commit('setLiveCard', param);
  },
};

const getters = {
  itemId(state) {
    return state.itemId;
  },
  itemState(state) {
    return state.itemState;
  },
  todayCount(state) {
    return state.todayCount;
  },
  inputActive(state) {
    return state.inputActive;
  },
};

const obj = {
  state,
  mutations,
  actions,
  getters,
};

export default obj;
