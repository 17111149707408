// 解决 ElTable 自动宽度高度
import { Table } from 'element-ui';
const fixElTableErr = (table) => {
  const oldResizeListener = table.methods.resizeListener;
  // eslint-disable-next-line no-param-reassign
  table.methods.resizeListener = function () {
    window.requestAnimationFrame(oldResizeListener.bind(this));
  };
};
fixElTableErr(Table);
