import Layout from '@/layout';

const routes = [
  {
    path: '/trafficstatus',
    component: Layout,
    index: 10,
    redirect: '/trafficstatus/index',
    meta: { permissions: ['road:list'], title: '实时路况', icon: 'el-icon-form' },
    children: [
      {
        path: 'index',
        name: 'trafficstatus',
        component: () => import('@/modules/trafficstatus/index'),
        meta: { title: '路况信息', permissions: ['road:roadinfo:list'] },
      },
      {
        path: 'statistical',
        name: 'trafficstatus:statistical',
        component: () => import('@/modules/trafficstatus/statistical'),
        meta: { title: '统计', permissions: ['road:statistical:list'] },
      },
    ],
  },
];

export default routes;
