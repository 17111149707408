import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import $log from './core/log';
import { LoopTimeoutTask } from '@/core/utils/tools';

let constantRoutes = [];
const whiteList = ['/auth/login', '/auth/register', '/qrcode']; // 不检查token的页面路径
/**
 * 查找modules下所有目录下的router.js文件，如果有，加入到这里。
 */
const routersContext = require.context('./modules/', true, /router.js$/);
routersContext.keys().forEach((key) => {
  const arr = routersContext(key).default;
  const result = arr.map(router => Object.assign({ index: 100 }, router));
  constantRoutes = constantRoutes.concat(result);
});

Vue.use(Router);
/**
 *
 * hidden: true                   是否隐藏
 * redirect: noRedirect           重定向的path
 * name:'router-name'             路由名称，使用 <keep-alive> 必填
 * index: 1,                      菜单排序号，由小到大排列
 * alwaysShow: true,             是否始终显示
 * meta : {
    roles: ['admin','editor']    菜单权限
    title: 'title'               侧边栏展示的名称
    icon: 'svg-name'/'el-icon-x' 菜单 Icon
  }
 */
constantRoutes.splice(
  constantRoutes.length,
  0,
  ...[
    {
      path: '/qrcode',
      meta: { title: '中转' },
      component: () => import('@/modules/auth/qrcode'),
      hidden: true,
    },
    // 404 page must be placed at the end !!!
    { path: '*', index: 10000, component: () => import('@/modules/404'), hidden: true },
  ],
);

constantRoutes.forEach((item) => {
  if (item.index === undefined) {
    Object.assign(item, { index: 1 });
  }
});
constantRoutes.sort((itemA, itemB) => itemA.index - itemB.index);

// 创建路由实例
const router = new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

const getRedTitle = () => {
  let timer = {};
  timer = LoopTimeoutTask.start(() => {
    try {
      store.dispatch('getRoadRedTitle');
      store.dispatch('audience/getPersonRedTitle');
    } catch (err) {
      LoopTimeoutTask.stop(timer);
    }
  }, 30000);
};

const checkUser = async (to, from, next) => {
  try {
    const { pwdNumber } = await store.dispatch('getUserInfo');
    getRedTitle();
    // 没有 live:list 退出再登录
    const listPermissions = !store.getters.permissions.includes('live:list');
    const jumpPermissions = to.meta.permissions;
    if (jumpPermissions) {
      if (store.getters.permissions[0] === '*:*:*') {
        next();
        return;
      }
      if (to?.path === '/live/list' && listPermissions) {
        next({ name: 'trafficstatus' });
      } else {
        const fromRouter = [...store.getters.permissions].includes(jumpPermissions[0]);
        if (!fromRouter) {
          next('*');
        }
      }
    }
    if (pwdNumber > 0) {
      next();
    } else {
      next('/auth/changePassword');
    }
  } catch (error) {
    await store.dispatch('resetToken');
    next('/auth/login');
  }
};

router.beforeEach(async (to, from, next) => {
  // set page title
  document.title = to.meta.title || '出行FM+';
  $log.logRemote('router', `从 ${from?.path} 切换页面到 ${to?.path}`);
  const { token } = store.getters;
  if (!token) {
    if (whiteList.indexOf(to.path) !== -1) {
      // 白名单路径，直接访问
      next();
      return;
    }
    // 重定向到登录页
    next('/auth/login');
    return;
  }
  // 有token
  const needChangePassword = store.getters['auth/userInfo'].userId !== '';
  if (needChangePassword && to.path === '/auth/changePassword') {
    next();
    return;
  }
  const hasUserId = store.getters.userInfo.userId !== '';

  const showPermissions = to.meta.permissions;

  if (!hasUserId || showPermissions) {
    checkUser(to, from, next);
    return;
  }

  if (to.path === '/auth/login') {
    next({ path: '/' });
    return;
  }
  next();
});
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

router.afterEach(() => {
  // finish progress bar
});

export default router;
