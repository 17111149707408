export function replaceNumber(value) {
  const newValue = value.replace(/\D|^0/g, '').substring(0, 7);
  return newValue;
}

/**
 * 将以分为单位的价格转换为以元为单位的价格
 * @param cent 分
 */
export function centToYuan(cent, fixedLength = 2, removeTrailingZero = true) {
  let length = parseInt(fixedLength, 10);
  if (isNaN(length) || length < 0) {
    length = 0;
  }
  const yuan = cent / 100;
  const yuanStr = typeof yuan === 'number' && !isNaN(yuan) ? yuan.toFixed(length) : String(yuan);

  if (!removeTrailingZero) {
    return yuanStr;
  }

  // 去除末尾的0
  if (/^\d+\.0*$/.test(yuanStr)) {
    // 小数部分全是0
    return yuanStr.replace(/^(\d+)(\.0*)$/, (m, s1) => s1);
  }
  return yuanStr.replace(/^(\d+\.\d*[1-9]{1})(0*)$/, (m, s1) => s1);
}

export class LoopTimeoutTask {
  /**
   * setTimeout递归实现interval,解决错误中断定时器的问题
   * @param callback 回调函数
   * @param timeout number 格式，循环时间
   * @param immediate 第一次执行 boolean格式，默认  true
   * @returns timer对象，用来清除定时器
   */
  static start(callback, timeout, immediate = true) {
    const timer = {};
    let isImmediate = immediate;

    function fn() {
      timer?.id && clearTimeout(timer.id);

      try {
        isImmediate && callback && callback();
      } finally {
        timer.id = setTimeout(() => {
          if (!isImmediate) {
            isImmediate = true;
          }
          fn();
        }, timeout);
      }
    }

    fn();
    return timer;
  }

  static stop(timer) {
    typeof timer === 'object' && clearTimeout(timer.id);
  }
}
