<template>
  <el-dropdown
    class="avatar-container"
    trigger="click"
  >
    <div class="avatar-wrapper">
      <img
        :src="radio.radioIcon"
        class="user-avatar"
      >
      <i class="el-icon-arrow-down avatar-down" />
    </div>
    <el-dropdown-menu
      slot="dropdown"
      class="user-dropdown"
    >
      <el-dropdown-item disabled>
        {{ radio.radioName }}
      </el-dropdown-item>
      <el-dropdown-item
        disabled
      >
        {{ userInfo.realName }} {{ roleNameList[0] ? '(' + roleNameList[0] + ')' : '' }}
      </el-dropdown-item>
      <el-dropdown-item disabled>
        {{ userInfo.email }}
      </el-dropdown-item>
      <router-link
        to="/"
        class="link"
      >
        <el-dropdown-item>
          <div class="divided" />
          <svg-icon
            icon-class="opinion"
            class="svg-icon"
          />意见反馈
        </el-dropdown-item>
      </router-link>
      <el-dropdown-item @click.native="logout">
        <svg-icon
          icon-class="logout"
          class="svg-icon"
        />退出登录
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavbarAvatar',
  data() {
    return {
      roleNameList: [],
    };
  },
  computed: {
    ...mapGetters(['sidebar', 'userInfo', 'radio']),
  },
  created() {
    this.roleList();
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
      this.$log.logRemote(this.userInfo.userId, '退出登录');
      this.$router.push('/auth/login');
    },
    roleList() {
      this.userInfo.roles.forEach((item) => {
        this.roleNameList.push(item.roleName);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  margin-right: 13px;
  width: 1.2rem;
  height: 1.2rem;
}

.el-popper.user-dropdown {
  border-radius: 10px;
  margin-top: 25px;

  .el-dropdown-menu__item {
    color: #333;
    line-height: 36px;
    padding: 0 27px;

    .divided {
      border-top: 1px solid rgba(151, 151, 151, .5);
      margin-top: 10px;
      padding-bottom: 10px;
    }

    &:last-of-type {
      color: #666;
    }
  }
}

.avatar-container {
  margin-right: 30px;
  width: 100%;

  .avatar-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar-down {
      font-size: 30px;
    }

    .user-avatar {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      margin: 0 7px 0 20px;
      border: 1px solid #ddd;
    }

    .el-icon-caret-bottom {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 25px;
      font-size: 12px;
    }
  }
}
</style>
