import Vue from 'vue';

const requireDirectives = require.context('.', true, /index.js$/);
requireDirectives.keys().forEach((fileName) => {
  if (fileName === './index.js') return; // 说明是本文件
  const config = requireDirectives(fileName);
  let name = fileName.replace(/\.\/(\w+).+/, '$1');
  if (config && config.default && config.default.name) {
    name = config.default.name;
  }
  Vue.directive(name, config.default || config);
});
