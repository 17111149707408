import Layout from '@/layout';

const routes = [
  {
    path: '/audience',
    component: Layout,
    index: 20,
    redirect: '/audience/listenInfo',
    name: 'resourceList',
    meta: { title: '听众管理', icon: 'el-icon-yonghuguanli', permissions: ['audience:list'] },
    children: [
      {
        path: 'listenInfo',
        name: 'listenInfo',
        component: () => import('@/modules/audience/index'),
        meta: { title: '听众', permissions: ['audience:audienceinfo:list'] },
      },
      {
        path: 'details',
        name: 'details',
        hidden: true,
        component: () => import('@/modules/audience/components/personalDetails'),
        meta: { title: '用户详情' },
      },
      {
        path: 'personMsg',
        name: 'personMsg',
        component: () => import('@/modules/audience/personMsg'),
        meta: { title: '私信', permissions: ['audience:personal:list'] },
        children: [
          {
            path: '',
            name: '',
            hidden: true,
            component: () => import('@/modules/audience/personComponents/index'),
            meta: { title: '私信', permissions: ['audience:personal:list'] },
          },
        ],
      },
    ],
  },
];

export default routes;
