<template>
  <section class="app-main">
    <div class="app-container">
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <router-view :key="key" />
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/variables.scss';

.app-main {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 65px 16px;
  background-color: $appMainBg;

  .app-container {
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1440px) {
      width: 1220px;
    }

    @media screen and (min-width: 1441px) {
      width: 1620px;
    }
  }
}
</style>
