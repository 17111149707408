/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validPhone(str) {
  const phoneReg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
  return phoneReg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validateUrl(str) {
  const urlRegex = /^(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
  return urlRegex.test(str);
}

export function validateLimitNumber(start, end, documents) {
  return function (rule, value, callback) {
    const val = Number(value);
    if (val >= start && val <= end) {
      callback();
      return;
    }
    const defaultText = `限制数目${start}~${end}`;
    callback(documents || defaultText);
  };
}

export function validateLimitLength(start, end) {
  return function (rule, value, callback) {
    const val = getStrLen(value);
    if (val >= start && val <= end) {
      callback();
      return;
    }
    callback(`限制中文字数${start}~${end}`);
  };
}

export function validateNumber(warnStr) {
  return function (rule, value, callback) {
    if (value >= 0) {
      callback();
      return;
    }
    callback(warnStr);
  };
}

function getStrLen(sString) {
  let j = 0;
  const s = sString;
  if (s === '') return j;
  for (let i = 0; i < s.length; i = i + 1) {
    if (s.substr(i, 1).charCodeAt(0) > 255) j = j + 1;
    else j = j + 0.5;
  }
  return j;
}
