<template>
  <component
    :is="type"
    v-bind="linkProps(to)"
    :class="className"
  >
    <slot />
  </component>
</template>

<script>
import { isExternal } from '@/core/utils/validate';

export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    isExternal() {
      return isExternal(this.to);
    },
    type() {
      if (this.isExternal) {
        return 'a';
      }
      return 'router-link';
    },
    isCurrentPath() {
      if (this.$route.path === this.to) {
        return true;
      }
      return this.to.split('/').length > 2 && this.$route.path.includes(this.to);
    },
    className() {
      return this.isCurrentPath ? 'is-active' : '';
    },
  },
  methods: {
    linkProps(to) {
      if (this.isExternal) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener',
        };
      }
      return {
        to,
      };
    },
  },
};
</script>
