const routes = [
  {
    path: '/auth',
    component: () => import('@/modules/auth/index'),
    hidden: true,
    index: 0,
    children: [
      {
        path: 'login',
        meta: { title: '登录' },
        component: () => import('@/modules/auth/login'),
      },
      {
        path: 'changePassword',
        meta: { title: '修改密码' },
        component: () => import('@/modules/auth/changePassword'),
      },
      {
        path: 'register',
        meta: { title: '注册' },
        component: () => import('@/modules/auth/register'),
      },
    ],
  },
];

export default routes;
