<template>
  <div class="app-wrapper">
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div class="fixed-header">
        <div class="navbar-wrap">
          <navbar />
        </div>
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components';

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

@media screen and (max-width: 1600px) {
  .fixed-header {
    width: calc(100% - 180px);
    left: 180px;
  }
}

@media screen and (min-width: 1600px) {
  .fixed-header {
    width: calc(100% - 220px);
    left: 220px;
  }
}

.app-wrapper {

  position: relative;
  min-height: 100%;
  width: 100%;
  display: flex;

  @include clearfix;
}

.drawer-bg {
  background: #000;
  opacity: .3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 20px;
  right: 0;
  margin: auto;
  z-index: 2021;
  display: flex;
  justify-content: center;
  transition: width .28s;
  padding: 0 16px;

  @media screen and (max-width: 1440px) {
    .navbar-wrap {
      width: 1220px;
    }
  }

  @media screen and (min-width: 1440px) {
    .navbar-wrap {
      width: 1620px;
    }
  }

  .navbar-wrap {
    position: relative;
  }
}
</style>
