import Layout from '@/layout';

const routes = [
  {
    path: '/markting',
    component: Layout,
    index: 25,
    redirect: '/markting/welcome',
    name: 'marktingList',
    meta: { title: '营销', icon: 'el-icon-liwu', permissions: ['marketing:list'] },
    children: [
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import('@/modules/markting/welcome'),
        meta: { title: '新手欢迎卡', permissions: ['marketing:novice:list'] },
        children: [
          {
            path: '',
            name: '',
            hidden: true,
            component: () => import('@/modules/markting/components/welcomecontent/index'),
            meta: { title: '新手欢迎卡', permissions: ['marketing:novice:list'] },
          },
          {
            path: 'create',
            name: 'create',
            hidden: true,
            component: () => import('@/modules/markting/components/welcomecontent/form'),
            meta: { title: '创建活动' },
          },
          {
            path: 'edit',
            name: 'edit',
            hidden: true,
            component: () => import('@/modules/markting/components/welcomecontent/form'),
            meta: { title: '编辑活动' },
          },
          {
            path: 'record',
            name: 'record',
            hidden: true,
            component: () => import('@/modules/markting/components/welcomecontent/getRecord'),
            meta: { title: '领取记录详情' },
          },
        ],
      },
      {
        path: 'pullLottery',
        name: 'pullLottery',
        component: () => import('@/modules/markting/pullLottery'),
        meta: { title: '下拉抽奖', permissions: ['marketing:lucky:list'] },
        children: [
          {
            path: '',
            name: '',
            hidden: true,
            component: () => import('@/modules/markting/components/pullcontent/index'),
            meta: { title: '下拉抽奖', permissions: ['marketing:lucky:list'] },
          },
          {
            path: 'create',
            name: 'create',
            hidden: true,
            component: () => import('@/modules/markting/components/pullcontent/form'),
            meta: { title: '创建抽奖' },
          },
          {
            path: 'edit',
            name: 'edit',
            hidden: true,
            component: () => import('@/modules/markting/components/pullcontent/form'),
            meta: { title: '编辑抽奖' },
          },
        ],
      },
    ],
  },
];

export default routes;
