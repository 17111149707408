import api from '@/core/request';

const resultArr = [];
const delArr = [];

let id = 1;
const state = {
  resultList: [],
  saveData: null,
  itemRecordId: 0,
};

const actions = {
  async spliceResultList({ commit }, payload) {
    // 如果是删除并且是有节目
    if (payload.isDel) {
      payload.delId && delArr.push(payload.delId);

      if (payload.data[0].$id) {
        resultArr.splice(
          resultArr.findIndex(cur => payload.data[0].$id === cur.$id),
          1,
        );
      }
    }

    // 如果是编辑节目
    if (!payload.isDel) {
      payload.data.forEach((item) => {
        if (item.$id) {
          resultArr.splice(
            resultArr.findIndex(cur => item.$id === cur.$id),
            1,
            item,
          );
          return;
        }

        if (item.itemName) {
          const curItem = item;
          curItem.$id = id;
          resultArr.push(item);
          id = id + 1;
        }
      });
    }

    commit('spliceResultList', payload);
    commit('mergeEmptyValue', payload.cdx);
  },
  async updateList({ rootGetters, state, commit }) {
    await api.post('/business/item/updateItem', {
      updateItemList: resultArr,
      radioId: rootGetters.radio.radioId,
      itemRecordId: state.itemRecordId,
      itemDeleteIdList: delArr,
    });
    commit('resetList');
  },
};

const mutations = {
  setItemRecordId(state, itemRecordId) {
    state.itemRecordId = itemRecordId;
  },
  // 合并连续的空对象
  mergeEmptyValue(state, cdx) {
    const array = state.resultList[cdx];
    const ranges = [];
    let index = 0;
    while (index < array.length) {
      const rangeStartIndex = index;
      let next = array[index + 1];

      while (next && next.isEmpty && array[index].isEmpty && next.isEmpty === array[index].isEmpty) {
        index = index + 1;
        next = array[index + 1];
      }

      if (rangeStartIndex === index) {
        ranges.push(array[index]);
      } else {
        ranges.push({ ...array[rangeStartIndex], endTime: array[index].endTime });
      }

      index = index + 1;
    }
    state.resultList.splice(cdx, 1, ranges);
  },
  setResultList(state, list) {
    state.resultList = list;
    state.saveData = JSON.parse(JSON.stringify(list));
  },
  resetList() {
    resultArr.length = 0;
    delArr.length = 0;
  },
  spliceResultList(state, { cdx, rdx, spliceNum, data }) {
    state.resultList[cdx].splice(rdx, spliceNum, ...data);
  },
};

const getters = {
  resultList(state) {
    return state.resultList;
  },
  isPass(state) {
    return state.resultList.every(date => date.every(item => !item.isEmpty));
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
