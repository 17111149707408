<script>
import { mapGetters } from 'vuex';
export default {
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dotshow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['roadredtitle']),
  },
  render() {
    const { icon, title, dotshow } = this;
    const vnodes = [];

    if (icon) {
      if (icon.includes('el-icon')) {
        vnodes.push(<i class={[icon, 'menu-el-icon']} />);
      } else {
        vnodes.push(<svg-icon className="menu-icon" icon-class={icon} />);
      }
    }
    if (dotshow && this.$getPermissions(['road:audience-fact'])) {
      vnodes.push(<span slot="title">
          {title}
          <el-badge is-dot class="dot"></el-badge>
        </span>);
    } else {
      vnodes.push(<span slot="title">{title}</span>);
    }
    return <div class="menu-item">{vnodes}</div>;
  },
};
</script>

<style lang="scss" scoped>
.menu-icon {
  width: 25px;
  height: 25px;
}

::v-deep.dot {
  margin-top: -7px;
}

::v-deep .el-badge__content.is-dot {
  width: 10px;
  height: 10px;
  background-color: #f00;
}
</style>
